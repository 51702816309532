//
//
//
//
//
//
//

export default {
  name: 'IconPaymentsPath',
}
