import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _96ac9986 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _2ba846f2 = () => interopDefault(import('../pages/chat.vue' /* webpackChunkName: "pages/chat" */))
const _20b1cb1e = () => interopDefault(import('../pages/consumers.vue' /* webpackChunkName: "pages/consumers" */))
const _14e1fd9b = () => interopDefault(import('../pages/distributors.vue' /* webpackChunkName: "pages/distributors" */))
const _1662a326 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _d235d46c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4e80cfe6 = () => interopDefault(import('../pages/manufacturers/index.vue' /* webpackChunkName: "pages/manufacturers/index" */))
const _21352b57 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _d6049508 = () => interopDefault(import('../pages/orders-deprecated.vue' /* webpackChunkName: "pages/orders-deprecated" */))
const _572b7b48 = () => interopDefault(import('../pages/payments.vue' /* webpackChunkName: "pages/payments" */))
const _bea5b99a = () => interopDefault(import('../pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _8e410528 = () => interopDefault(import('../pages/scripts/index.vue' /* webpackChunkName: "pages/scripts/index" */))
const _e09ff556 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _767400ba = () => interopDefault(import('../pages/settings_old.vue' /* webpackChunkName: "pages/settings_old" */))
const _0933076f = () => interopDefault(import('../pages/tasks.vue' /* webpackChunkName: "pages/tasks" */))
const _81f66602 = () => interopDefault(import('../pages/catalog/attributes/index.vue' /* webpackChunkName: "pages/catalog/attributes/index" */))
const _19c8f784 = () => interopDefault(import('../pages/catalog/categories/index.vue' /* webpackChunkName: "pages/catalog/categories/index" */))
const _33c7561b = () => interopDefault(import('../pages/catalog/formulary/index.vue' /* webpackChunkName: "pages/catalog/formulary/index" */))
const _3283f45c = () => interopDefault(import('../pages/catalog/helper.js' /* webpackChunkName: "pages/catalog/helper" */))
const _5916abe9 = () => interopDefault(import('../pages/catalog/library/index.vue' /* webpackChunkName: "pages/catalog/library/index" */))
const _a5501f54 = () => interopDefault(import('../pages/catalog/similar-brands/index.vue' /* webpackChunkName: "pages/catalog/similar-brands/index" */))
const _6753e980 = () => interopDefault(import('../pages/catalog/synonyms/index.vue' /* webpackChunkName: "pages/catalog/synonyms/index" */))
const _40ed918b = () => interopDefault(import('../pages/manufacturers/distributors/index.vue' /* webpackChunkName: "pages/manufacturers/distributors/index" */))
const _808ef842 = () => interopDefault(import('../pages/settings/catalog/index.vue' /* webpackChunkName: "pages/settings/catalog/index" */))
const _0d8a4674 = () => interopDefault(import('../pages/settings/helper.js' /* webpackChunkName: "pages/settings/helper" */))
const _3583819f = () => interopDefault(import('../pages/settings/people/index.vue' /* webpackChunkName: "pages/settings/people/index" */))
const _7c3ceef6 = () => interopDefault(import('../pages/manufacturers/distributors/_id/index.vue' /* webpackChunkName: "pages/manufacturers/distributors/_id/index" */))
const _89f21888 = () => interopDefault(import('../pages/manufacturers/distributors/_id/practices.vue' /* webpackChunkName: "pages/manufacturers/distributors/_id/practices" */))
const _11f8f05e = () => interopDefault(import('../pages/manufacturers/_id/index.vue' /* webpackChunkName: "pages/manufacturers/_id/index" */))
const _584ac2c2 = () => interopDefault(import('../pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _46cf22d1 = () => interopDefault(import('../pages/reset/_hash/index.vue' /* webpackChunkName: "pages/reset/_hash/index" */))
const _256d8abd = () => interopDefault(import('../pages/manufacturers/_id/distributors.vue' /* webpackChunkName: "pages/manufacturers/_id/distributors" */))
const _65851a9a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/catalog",
    component: _96ac9986,
    name: "catalog"
  }, {
    path: "/chat",
    component: _2ba846f2,
    name: "chat"
  }, {
    path: "/consumers",
    component: _20b1cb1e,
    name: "consumers"
  }, {
    path: "/distributors",
    component: _14e1fd9b,
    name: "distributors"
  }, {
    path: "/forgot-password",
    component: _1662a326,
    name: "forgot-password"
  }, {
    path: "/login",
    component: _d235d46c,
    name: "login"
  }, {
    path: "/manufacturers",
    component: _4e80cfe6,
    name: "manufacturers"
  }, {
    path: "/orders",
    component: _21352b57,
    name: "orders"
  }, {
    path: "/orders-deprecated",
    component: _d6049508,
    name: "orders-deprecated"
  }, {
    path: "/payments",
    component: _572b7b48,
    name: "payments"
  }, {
    path: "/reset",
    component: _bea5b99a,
    name: "reset"
  }, {
    path: "/scripts",
    component: _8e410528,
    name: "scripts"
  }, {
    path: "/settings",
    component: _e09ff556,
    name: "settings"
  }, {
    path: "/settings_old",
    component: _767400ba,
    name: "settings_old"
  }, {
    path: "/tasks",
    component: _0933076f,
    name: "tasks"
  }, {
    path: "/catalog/attributes",
    component: _81f66602,
    name: "catalog-attributes"
  }, {
    path: "/catalog/categories",
    component: _19c8f784,
    name: "catalog-categories"
  }, {
    path: "/catalog/formulary",
    component: _33c7561b,
    name: "catalog-formulary"
  }, {
    path: "/catalog/helper",
    component: _3283f45c,
    name: "catalog-helper"
  }, {
    path: "/catalog/library",
    component: _5916abe9,
    name: "catalog-library"
  }, {
    path: "/catalog/similar-brands",
    component: _a5501f54,
    name: "catalog-similar-brands"
  }, {
    path: "/catalog/synonyms",
    component: _6753e980,
    name: "catalog-synonyms"
  }, {
    path: "/manufacturers/distributors",
    component: _40ed918b,
    name: "manufacturers-distributors"
  }, {
    path: "/settings/catalog",
    component: _808ef842,
    name: "settings-catalog"
  }, {
    path: "/settings/helper",
    component: _0d8a4674,
    name: "settings-helper"
  }, {
    path: "/settings/people",
    component: _3583819f,
    name: "settings-people"
  }, {
    path: "/manufacturers/distributors/:id",
    component: _7c3ceef6,
    name: "manufacturers-distributors-id"
  }, {
    path: "/manufacturers/distributors/:id/practices",
    component: _89f21888,
    name: "manufacturers-distributors-id-practices"
  }, {
    path: "/manufacturers/:id",
    component: _11f8f05e,
    name: "manufacturers-id"
  }, {
    path: "/orders/:id",
    component: _584ac2c2,
    name: "orders-id"
  }, {
    path: "/reset/:hash",
    component: _46cf22d1,
    name: "reset-hash"
  }, {
    path: "/manufacturers/:id/distributors",
    component: _256d8abd,
    name: "manufacturers-id-distributors"
  }, {
    path: "/",
    component: _65851a9a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
