export const items = [
  {
    id: 1,
    title: 'Dashboard',
    route: '/',
    icon: 'Dashboard',
    alias: 'dashboard',
  },
  {
    id: 2,
    title: 'Tasks',
    route: '/tasks',
    icon: 'Tasks',
    alias: 'tasks',
  },
  {
    id: 3,
    title: 'Catalog',
    route: '/catalog/library',
    icon: 'Catalog',
    alias: 'catalog',
  },
  {
    id: 4,
    title: 'Partners',
    route: '/manufacturers/distributors',
    icon: 'Partners',
    alias: 'distributors',
  },
  {
    id: 5,
    title: 'Orders',
    route: '/orders',
    icon: 'Orders',
    alias: 'orders',
  },
  {
    id: 9,
    title: 'Payments',
    route: '/payments',
    icon: 'Payments',
    alias: 'payments',
  },
  {
    id: 6,
    title: 'Consumers',
    route: '/consumers',
    icon: 'Consumers',
    alias: 'consumers',
  },
  {
    id: 7,
    title: 'Chat',
    route: '/chat',
    icon: 'Chat',
    alias: 'chat',
  },
  {
    id: 8,
    title: 'Settings',
    route: '/settings/people',
    icon: 'Settings',
    alias: 'settings',
  },
]
